import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from "pinia";
// 创建pinia实例
const pinia = createPinia()
import router from './router/router.js'
import 'element-plus/dist/index.css';
import ElementPlus from 'element-plus'
import 'highlight.js/lib/common'
import hljsVuePlugin from '@highlightjs/vue-plugin'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import "@/assets/css/blue.scss";

createApp(App)
  .use(ElementPlus, { locale: zhCn })
  .use(router)
  .use(hljsVuePlugin)
  .use(pinia)
  .mount('#app')
