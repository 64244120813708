import { ElMessageBox } from "element-plus";
import { IMAGE_URL, baseURL } from "./env";

export function regPhone(mobile) {
  return /^1[3-9]\d{9}$/.test(mobile)
}
export function copyContent(item) {
  var input = document.createElement("textarea"); // 创建input对象
  input.value = item.replace(/(\n\n)/g, '\n'); // 设置复制内容
  document.body.appendChild(input); // 添加临时实例
  input.select(); // 选择实例内容
  document.execCommand("Copy"); // 执行复制
  document.body.removeChild(input); // 删除临时实例
  ElMessage.success('复制成功！');
}

export function getImageUrl(url) {
  return new URL(`../assets/img/menu/${url}`, import.meta.url).href
}

export const addImagePrefix = (url = '') => {
  if (url && url.includes('aislogo')) {
    return url;
  }
  
  // comfyUI绘图上传图片地址
  if(url.includes('img2img')) {
    return `${baseURL}/staticSource/comfyui?src=${url}&type=input`
  }

  if (url && !url.startsWith('http')) {
    if(url.split(',').length > 0) {
      return `${IMAGE_URL}${url.split(',')[0]}`
    }
    return `${IMAGE_URL}${url}`
  }
  return url;
}

export const copyUrl = (text = '', successText = '复制成功') => {
  navigator.clipboard.writeText(text).then(() => {
    ElMessage.success(successText);
  });
}

/**
 * checkbox属性转换
 */
export function convertStringToCheckboxArr(arr, defaultValue = []) {
  const newArr = arr.map(item => {
    const sub = {
      name: item,
      checked: defaultValue.includes(item),
      disabled: false
    }
    return sub;
  })
  return newArr;
}

/**
 * 将Date转换成yyyy-MM-dd:hh :mm:ss格式
 */
export const dateFormatter = new Intl.DateTimeFormat('zh-CN', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit'
});

// 生成唯一的id
export const uuid = () => {
  let s = [];
  let hexDigits = "0123456789abcdef";
  for (let i = 0; i < 32; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23];
  let uuid = s.join("");
  return uuid;
}

export const loginBox = (store) => {
  ElMessageBox.confirm('请先登录', '提示', {
    confirmButtonText: '去登录',
    cancelButtonText: '稍后体验',
    type: 'info'
  }).then(() => {
    store.setLoginVisible(true);
  }).catch()
}

export const deepCopy = (obj) => {
  return JSON.parse(JSON.stringify(obj))
}